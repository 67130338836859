// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academia-de-software-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/academia-de-software.js" /* webpackChunkName: "component---src-pages-academia-de-software-js" */),
  "component---src-pages-agencia-de-software-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/agencia-de-software.js" /* webpackChunkName: "component---src-pages-agencia-de-software-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-contacto-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-staffing-ti-js": () => import("/Applications/MAMP/htdocs/gatsby/high-flyers/src/pages/staffing-ti.js" /* webpackChunkName: "component---src-pages-staffing-ti-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Applications/MAMP/htdocs/gatsby/high-flyers/.cache/data.json")

